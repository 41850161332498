<template>
    <!--  用户纠错记录-->
    <div class="exercisesType">
      <div style="display: flex; justify-content: space-between">
        <div class="menu_select">
          <h4 style="padding: 10px 10px 0px 24px">模块</h4>
          <a-menu style="width: 100%" mode="inline" v-model="menu">
            <a-menu-item
              v-for="(item, index) in menuList"
              :key="index"
              @click="leftChange(item)"
              >{{ item.name }}</a-menu-item
            >
          </a-menu>
        </div>
        <div class="InfoTable">
          <div style="margin:15px 0 0 15px;font-weight:bold">用户纠错记录</div>
          <div class="navTop">
            <div class="datePicker">
              日期：
              <a-range-picker
                v-model="timeVal"
                @change="onChange_range_picker " 
                style="width:200px"
              />
            </div>
            <div>
                <!-- default-value="0" -->
              <a-radio-group v-model="replyStatus">
                状态：
                <a-radio-button value="0" @click="changRadio('0')">
                  待回复</a-radio-button
                >
                <a-radio-button value="1" @click="changRadio('1')">
                  已回复
                </a-radio-button>
              </a-radio-group>
            </div>
            <div>
                <a-button @click="ExportThisModule()" style="margin-right:20px"> <a-icon type="file-excel" style="color:green"/>导出该模块</a-button>
                <a-button @click="ExportAllModule()"> <a-icon type="file-excel" style="color:green" />导出全部模块</a-button>
            </div>
          </div>
          <div class="nav">
            <a-table
              :columns="columns"
              :data-source="messageList"
              :rowKey="(record) => record.id"
              :pagination="false"
              style="margin: 15px"
              :scroll="{ x: 1500}"
            >
              <!-- 题目名称 -->
              <span slot="sort" slot-scope="text, record, index">
                <a @click="getByName(record)">{{ record.questionName }}</a>
              </span>  
              <!-- 姓名 -->
              <span slot="createdByName" slot-scope="text, record, index">
                <a @click="getByTopic(record)">{{ record.createdByName }}</a>
              </span>
              <span slot="errorStatus" slot-scope="text, record, index,item">
                <a  v-if="record.replyStatus===0">待回复</a>
                <a  v-if="record.replyStatus===1" style="color:#4ab32c">已回复</a>
              </span> 
              <span slot="operation" slot-scope="text, record, index">
                <a style="margin-right: 10px" @click="editBtn(record)">修改题目</a>
                <a style="margin-right: 10px" @click="getDeatil(record)">详情</a>
                <a @click="toReply(record)">回复</a>
              </span>
            </a-table>
          </div>
          <div
            style="
              margin-top: 28px;
              margin-left: 10px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <span
              style="
                font-size: medium;
                color: #929292;
                font-size: 14px;
                bottom: 3%;
              "
            >
              共{{ total }}条记录 第 {{ pageNo }} /
              {{ Math.ceil(total / pageSum) }} 页
            </span>
            <a-pagination
              style="float: right"
              show-quick-jumper
              show-size-changer
              :page-size.sync="pageSum"
              :total="total"
              v-model="pageNo"
              @change="pageChange"
              @showSizeChange="pageChange"
            />
          </div>
        </div>
      </div>
  
      <!-- 题目记录/纠错记录列表 -->
      <a-drawer
        :title="tableTitle"
        width="70%"
        :visible="addVisible"
        :body-style="{ paddingBottom: '50px' }"
        @close="addCancel"
      >
        <!-- 展示用户信息 column="7" :一行7个格子 -->
        <a-descriptions title="用户信息" :column="7" size="middle" v-if="tableTitle == '纠错记录'">
          <a-descriptions-item label="姓名">
            {{ this.userName }}
          </a-descriptions-item>
          <a-descriptions-item label="医院" span="2">
            {{ this.hospitalName }}
          </a-descriptions-item>
          <a-descriptions-item label="科室">
            {{ this.hospitalOffice }}
          </a-descriptions-item>
          <a-descriptions-item label="职称">
             {{ this.jobTitle }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions title="题目名称" size="middle" v-if="tableTitle == '题目记录'">
          <a-descriptions-item>
            {{ questionName }}
          </a-descriptions-item>
        </a-descriptions>

        <!-- 展示记录信息 -->
        <a-descriptions title="记录信息" style="margin-top:20px">
        </a-descriptions>
        <!-- 按人 -->
        <a-table
          :columns="recordColumns"
          :data-source=" recordList"
          :rowKey="(record) => record.id"
          :pagination="false"
          v-if="tableTitle == '纠错记录'"
        > 
          <span slot="operation" slot-scope="text, record, index">
            <a @click="getDeatil(record)">详情</a>
          </span>
        </a-table>
        <!-- 按人分页 -->
        <div
           v-if="tableTitle == '纠错记录'"
            style="
              margin-top: 28px;
              margin-left: 10px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <span
              style="
                font-size: medium;
                color: #929292;
                font-size: 14px;
                bottom: 3%;
              "
            >
              共{{ recordTotal }}条记录 第 {{ recordPageNo }} /
              {{ Math.ceil( recordTotal / recordPageSum) }} 页
            </span>
            <a-pagination
              style="float: right"
              show-quick-jumper
              show-size-changer
              :page-size.sync="recordPageSum"
              :total="recordTotal"
              v-model="recordPageNo"
              @change="recordPageChange"
              @showSizeChange="recordPageChange"
            />
        </div>

        <!-- 按题目 -->
        <a-table
          :columns="recordByTitleColumns"
          :data-source="recordByTitleList"
          :rowKey="(record) => record.id"
          :pagination="false"
          v-if="tableTitle == '题目记录'"
        > 
          <span slot="operation" slot-scope="text, record, index">
            <a @click="getDeatil(record)">详情</a>
          </span>
        </a-table>
        <!-- 按题目分页 -->
        <div
            v-if="tableTitle == '题目记录'"
            style="
              margin-top: 28px;
              margin-left: 10px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <span
              style="
                font-size: medium;
                color: #929292;
                font-size: 14px;
                bottom: 3%;
              "
            >
              共{{ recordTitleTotal }}条记录 第 {{ recordTitlePageNo }} /
              {{ Math.ceil( recordTitleTotal / recordTitlePageSum) }} 页
            </span>
            <a-pagination
              style="float: right"
              show-quick-jumper
              show-size-changer
              :page-size.sync="recordTitlePageSum"
              :total="recordTitleTotal"
              v-model="recordTitlePageNo"
              @change="recordTitlePageChange"
              @showSizeChange="recordTitlePageChange"
            />
        </div>
      </a-drawer>
      <!-- 详情 -->
      <a-drawer
        title="详情"
        :width="790"
        :visible="editVisible"
        :body-style="{ paddingBottom: '50px' }"
        @close="editCancel" 
        style="z-index:99999"
      >
        <!-- 用户信息 column="7" :一行7个格子 -->
        <a-descriptions title="用户信息" :column="6" >
          <a-descriptions-item label="姓名">
            {{ this.userName }}
          </a-descriptions-item>
          <a-descriptions-item label="医院" span="2">
            {{ this.hospitalName }}
          </a-descriptions-item>
          <a-descriptions-item label="科室" >
            {{ this.hospitalOffice }}
          </a-descriptions-item>
          <a-descriptions-item label="职称">
             {{ this.jobTitle }}
          </a-descriptions-item>
        </a-descriptions>
        <!-- 纠错信息 -->
        <a-descriptions title="纠错信息" :column="1">
          <a-descriptions-item label="关联题目">
            {{ this.RelatedQuestions }}
          </a-descriptions-item>
          <a-descriptions-item label="错误类型" span="2">
            {{ this.Error2CorrectionType }}
          </a-descriptions-item>
          <!-- <a-descriptions-item label="纠错内容">
            {{ this.NotesContent }}
          </a-descriptions-item> -->
          <a-descriptions-item style="margin-left:70px">
            <div style="display: flex;"> 
              <span style="display:block;width: 70px;">纠错内容：</span>
              <span style="display:block;">
                {{ this.NotesContent }}
              </span>
            </div>
          </a-descriptions-item>
          <a-descriptions-item >
            <span style="margin-left:70px">
             {{ this.CorrectionTime }}
            </span>
          </a-descriptions-item>
        </a-descriptions>
        <!-- 平台回复 详情 -->
        <a-descriptions title="平台回复" :column="1" v-if="this.replyOrDeatil == '详情' && this.detailReplyStatus == 1">
          <a-descriptions-item  v-if="this.detailReplyStatus == 1">
            <div style="display: flex;">
              <div style="height: 22px;width:70px">回复内容：</div>
              <div style="width:662px">
                {{ this.replyContent }}
              </div>
              <!-- v-model="form.content" -->
              <!-- <a-textarea auto-size placeholder="请输入描述" style="min-height: 150px;font-size: 16px"/> -->
            </div>
          </a-descriptions-item>
          <a-descriptions-item v-if="this.detailReplyStatus == 1">
            <span style="margin-left:70px">
             {{ this.recoveryTime }}
            </span>
          </a-descriptions-item>
        </a-descriptions>
        <!-- 平台回复 带回复的 -->
        <a-descriptions title="平台回复" :column="1" v-if="this.replyOrDeatil == '回复'" class="ceshide">
          <a-descriptions-item style="margin-left:70px;">
            <div style="display: flex;" >
              <span style="display:block;width: 80px;" >回复内容：</span>
              <!-- <span style="display:block;">
                {{ this.replyContent }}
              </span> -->
              <!-- v-model="form.content" -->
              <a-textarea  v-model="formContent" auto-size placeholder="请输入描述" style="min-height: 150px;font-size: 16px;width:600px"/>
            </div>
          </a-descriptions-item>
        </a-descriptions>
        <div
           v-if="this.replyOrDeatil == '回复'"
          :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button @click="addCancel"> 取消 </a-button>
          <a-button style="margin-left: 10px" type="primary" @click="tijiaoOK()">
            确定
          </a-button>
        </div>
      </a-drawer>


      <a-drawer
          :get-container="false"
          title="修改"
          width="50%"
          :visible="editShow"
          @close="()=>{this.editShow = false;this.editType=false;this.typeInfoModel = [];this.modelPagination.title = '';this.modelPagination.pageNo = 1}"
      >
        <a-form-model style="margin-bottom: 40px" :rules="rules" :model="editForm" :label-col="labelColLive" :wrapper-col="wrapperCol" ref="editItem" >
          <a-form-model-item label="类型" prop="type">
            <a-radio-group @change="()=>{
           if(editForm.type === 'radio'){
             this.editForm.able = '单选题'
           }else {
             this.editForm.able = '多选题'
           }
         }" v-model="editForm.type">
              <a-radio value="radio" >
                单选
              </a-radio>
              <a-radio value= "checkbox">
                多选
              </a-radio>
            </a-radio-group>
            <a @click="openSample(!editType ? 'questionBank_type_common':'questionBank_type_recombination')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>
          </a-form-model-item>
          <a-form-model-item label="分类" >
            <!--          <a-select-->
            <!--              placeholder="选择分类"-->
            <!--              v-model="editForm.questionCode"-->
            <!--              mode="multiple"-->
            <!--              :maxTagCount= 3-->
            <!--              showArrow-->
            <!--              :maxTagTextLength = 7-->
            <!--          >-->
            <!--            <a-select-option v-for="item in typeInfoModel" :key="item.id" :value="item.code">-->
            <!--              {{item.name}}-->
            <!--            </a-select-option>-->
            <!--          </a-select>-->

            <a-select
                placeholder="选择分类"
                v-model="editForm.questionCode"
                mode="multiple"
                :maxTagCount= 3
                showArrow
                :maxTagTextLength = 7
                show-search
                option-filter-prop="children"
                @popupScroll="popupScroll_questionCode"
                @search="select_questionCode"
            ><a-select-option v-for="item in typeInfoModel" :key="item.id" :value="item.code">
              {{item.name}}
            </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="题目名称" prop="name">
            <div style="display: flex">
              <a-input  v-model="editForm.name"  placeholder="输入名称" @change="editTitle" />
              <a @click="openSample(!editType ? 'questionBank_name_common':'questionBank_name_recombination')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>
            </div>
            <ul  class="titleList" v-for="item in titleList">
              {{item.name}}
            </ul>
          </a-form-model-item>
          <a-form-model-item label="选项" prop="options">
            <div style="width: 100%;display: flex">
              <div style="width: 90%;">
                <div style="display: flex;align-items: center"  v-for="(item,index) in editForm.options" :key="index">
                  <a-input placeholder="输入选项" style="width: 220px;margin-right: 10px" v-model="item.title" />
                  <a-icon type="plus-circle" v-if="index <=0" style="cursor: pointer;font-size: 18px;color: #1890ff;margin-right: 10px"  @click="editOptions" />
                  <a-icon type="minus-circle" v-if="index!==0" style="cursor: pointer;font-size: 18px;margin-right: 10px"   @click="delEditOptions(item,index)" />
                  <input  type="checkbox" v-model="item.answer" v-if="editForm.type==='checkbox'" :id="'check'+index" @change="resultKeyEdit(item,index)">
                  <input  type="radio" name="radio" :value="item.answer" v-if="editForm.type==='radio'" :id="'radio'+index" @change="resultRadioEdit(item,index)">
                  正确答案
                  <a-input-number v-show="editType" v-if="item.answer===1 " v-model="item.socore" style="margin-left: 5px;flex: 1" placeholder="请输入分值"  />
                </div>
              </div>
              <div style="float: left;width: 50px">
                <a @click="openSample(!editType ? 'questionBank_options_common':'questionBank_options_recombination')" style="width: 50px;">示例图</a>
              </div>
            </div>
          </a-form-model-item>
          <a-form-model-item label="解析" >
            <div style="display: flex">
              <a-textarea v-model="editForm.parse" />
              <a @click="openSample(!editType ? 'questionBank_parse_common':'questionBank_parse_recombination')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>
            </div>
          </a-form-model-item>
          <a-form-model-item label="题干" prop="stemId"  v-if="editType===true">
            <div style="display: grid">
              <div style="display: flex">
                <a-button type="primary" icon="search" style="margin-bottom: 5px;width: 75px" @click="()=>{this.addStem=true;this.getStem(1,10)}">搜索</a-button>
                <a @click="openSample(!editType ? 'questionBank_stemLog_common':'questionBank_stemLog_recombination')" style="width: 50px;margin-left: 3px">示例图</a>
              </div>
              <span>{{editForm.stemLog}}</span>
            </div>
          </a-form-model-item>
          <!--        <a-form-model-item label="标签" prop="able"  v-if="editType===true">-->
          <!--          <a-input  v-model="editForm.able"   placeholder="请输入"  />-->
          <!--        </a-form-model-item>-->
          <a-form-model-item label="标签" prop="able">
            <div style="display: flex">
              <a-input  v-model="editForm.able"   placeholder="请输入"  />
              <a @click="openSample(!editType ? 'questionBank_able_common':'questionBank_able_recombination')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>
            </div>
          </a-form-model-item>
        </a-form-model>

        <div
            :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
        >
          <a-button style="marginRight: 8px" @click="()=>{this.editShow = false;this.editType=false;this.typeInfoModel = [];this.modelPagination.title = '';this.modelPagination.pageNo = 1}">
            取消
          </a-button>
          <a-button type="primary" @click="editOk">
            确认
          </a-button>
        </div>
      </a-drawer>

      <a-drawer
          title="选择题干"
          width="50%"
          style="z-index: 1001"
          :visible="addStem"
          @close="()=>{this.addStem = false;this.selectedRowKeys=[]}"
      >

        <div style="display: flex;justify-content: space-between">
          <a-input-search placeholder="请输入" style="width: 60%;margin-bottom: 5px" enter-button="搜索"   @search="stemSearch" />
          <a-button style="margin-bottom: 5px" type="primary" @click="stemAdd">新增题干</a-button>
        </div>

        <a-table  bordered :columns="stemColumns"  :data-source="stemTable" :pagination="false" rowKey="id"
                  :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange,type: 'radio'}"
        >
        <span slot="name" slot-scope="text,item">
          <span>{{text.name}}</span>
          <span v-if="text.name===''">
            <a-textarea placeholder="请输入" style="width: 80%;margin-right: 5px"  v-model="stemValue"   @keyup.enter="addStemOk" /> <a-icon type="check" class="addStem" style="cursor:pointer"  @click="addStemOk"/>
          </span>
        </span>
        </a-table>
        <div style="margin-top: 10px;align-items: center;display: flex;justify-content: space-between">
          <span style="font-size: medium;color: #929292">共 {{stemPagination.total}} 条记录 第 {{ stemPagination.current }} / {{ Math.ceil(stemPagination.total / stemPagination.pageSize) }} 页</span>
          <a-pagination
              :page-size-options="stemPagination.sizeOptions"
              v-model="stemPagination.current"
              :total="stemPagination.total"
              show-size-changer
              :page-size="stemPagination.pageSize"
              @change="stemPage"
              @showSizeChange="stemPage"
              show-quick-jumper
              style="float: right"
          >
          </a-pagination>
        </div>
      </a-drawer>

    </div>
  </template>
  
  <script>
  import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
  import axios from "axios";
  import moment from "moment";
  import { current_date } from "@/utils/currentDate";
  import {
    getMoKuaiData,
    getErrSelectAll,
    getselectAllByQuestionId,
    getselectAllByMemberId,
    getSelectById,
    putReply,
    exportQuestionErrorCorrectionDataToExcel,
    getExamLeft,
    getTitleId,
    getTitleStem,
    postStem,
    getTitleList,
    putTitleExam, putTitleStem
  } from "@/service/titleExam_api";
  const editorConfig = {
    // JS 语法
    MENU_CONF: {},
  };
  const signature = "";
  // 修改 uploadImage 菜单配置
  editorConfig.MENU_CONF["uploadImage"] = {
    // 自定义上传
    async customUpload(file, insertFn) {
      let formData = new FormData();
      formData.append("file", file);
      // file 即选中的文件,自己实现上传，并得到图片 url alt href
      axios
        .post(
          "https://yun-new-admin-api.yunicu.com/api/admin/common/update",
          formData
        )
        .then((res) => {
          if (res.data.code === 0) {
            insertFn(res.data.data.url); // 最后插入图片
          }
        });
    },
  };
  // 修改 uploadVideo 菜单配置
  editorConfig.MENU_CONF["uploadVideo"] = {
    // 自定义上传
    async customUpload(file, insertFn) {
      let mediaFile = file;
      //获取签名
      axios
        .get("https://yun-new-admin-api.yunicu.com/v3/getUgcUploadSign")
        .then((res) => {
          if (res.data.code === 0) {
            const getSignature = () => {
              return res.data.data.signature;
            };
            const tcVod = new TcVod({
              getSignature: getSignature, //上传签名的函数
            });
            const uploader = tcVod.upload({
              mediaFile: mediaFile, // 媒体文件（视频或音频或图片），类型为 File
            });
            uploader.done().then((res) => {
              insertFn(res.video.url); // 进行视频处理：转码和截图
            });
          }
        });
    },
  };
  export default {
    name: "exercisesType",
    components: { Editor, Toolbar },
    data() {
      return {
        createdId:'',
        recordQuestionId:'',
        formContent:'',//回复内容框
        typeName:'',
        replyOrDeatil:'',
        detailReplyStatus:0,
        replyContent:'',
        recoveryTime:'',
        CorrectionTime:'',
        RelatedQuestions:'',
        NotesContent:'',
        ErrorCorrectionType:'',
        Error2CorrectionType:'',
        ERRDeatailsId:'',
        questionName:'',
        tableTitle:"",
        jobTitle:'',
        hospitalOffice:'',
        hospitalName:'',
        userName:'',
        recordList:[],
        recordByTitleList:[],
        timeVal:[],//日期
        StartTime:'',
        EndTime:'',
        editor: null,
        toolbarConfig: {},
        mode: "default", // or 'simple'
        editorConfig,
        menuList: [],
        messageList: [],
        year: moment(current_date()).format("YYYY"),
        yearShowOne: false,
        title: "",
        replyStatus: '', //已回复
        isTop: "", //置顶
        state: 0,
        pageNo: 1,
        pageSum: 10,
        total: 0,
        recordPageSum:10,
        recordPageNo:1,
        recordTotal:0,
        recordTitlePageNo:1,
        recordTitleTotal:0,
        recordTitlePageSum:10,
        id: "",
        menu: [0],
        addVisible: false,
        labelColLive: { span: 5 },
        wrapperCol: { span: 17 },
        editVisible: false,
        columns: [
          {
            title: "题目名称",
  
            // width: "10%",
            scopedSlots: { customRender: "sort" },
          },
          {
            title: "姓名",
            dataIndex: "createdByName",
            width: "8%",
            scopedSlots: { customRender: "createdByName" },
          },
          {
            title: "医院",
            dataIndex: "hospitalName",
            // width: "18%",
          },
          {
             title: '纠错类型',
             dataIndex: 'errorType',
             key: 'errorType',
             width: "12%",
             customRender(arr){
               return arr.map(item=>{  
                   return item;   //使其返回数组中的一项
               }).join("、");   //因为数据中user_name可能是两个人所以用逗号分隔开
             }
           },
          {
            title: "状态",
            // dataIndex: "replyStatus",
            width: "8%",
            scopedSlots: { customRender: "errorStatus" },
          },
          {
            title: "提交时间",
            dataIndex: "createdTime",
            width: "10%",
          },
          {
            title: "纠错内容",
            dataIndex: "errorContent",
            // width: "10%",
          },
          {
            title: "操作",
            scopedSlots: { customRender: "operation" },

            fixed: 'right',
          },
        ],
        // 按人
        recordColumns: [
          {
            title: "关联题目",
            dataIndex: "questionName",
            width: "28%",
          },
          {
            title: "提交时间",
            dataIndex: "createdTime",
            width: "20%",
          },
          {
             title: '错误类型',
             dataIndex: 'errorType',
             key: 'errorType',
             width: "12%",
             customRender(arr){
               return arr.map(item=>{  
                   return item;   //使其返回数组中的一项
               }).join("、");   //因为数据中user_name可能是两个人所以用逗号分隔开
             }
           },
          {
            title: "具体描述",
            dataIndex: "errorContent",
            // width: "10%",
          },
          {
            title: "操作",
            scopedSlots: { customRender: "operation" },
            width: 100,
          },
        ],
        // 按题目
        recordByTitleColumns: [
          {
            title: "用户名称",
            dataIndex: "createdByName",
            width: "10%",
          },
          {
            title: "医院",
            dataIndex: "hospitalName",
            width: "23%",
          },
          {
             title: '错误类型',
             dataIndex: 'errorType',
             key: 'errorType',
             width: "12%",
             customRender(arr){
               return arr.map(item=>{  
                   return item;   //使其返回数组中的一项
               }).join("、");   //因为数据中user_name可能是两个人所以用逗号分隔开
             }
           },
          {
            title: "提交时间",
            dataIndex: "createdTime",
            width: "15%",
          },
          {
            title: "具体描述",
            dataIndex: "errorContent",
            // width: "10%",
          },
          {
            title: "操作",
            scopedSlots: { customRender: "operation" },
            width: 100,
          },
        ],
        editRules: {
          title: [{ required: true, message: "请输入", trigger: "blur" }],
          subtitle: [{ required: true, message: "请输入", trigger: "blur" }],
          issueDate: [{ required: true, message: "请选择", trigger: "change" }],
          text: [{ required: true, message: "请输入", trigger: "blur" }],
        },
        rules: {
          examTypeId:[{ required: true, message: '请选择', trigger: 'blur' }],
          board:[{ required: true, message: '请选择', trigger: 'blur' }],
          name:[{ required: true, message: '请输入名称', trigger: 'blur' }],
          code:[{ required: true, message: '请输入code值', trigger: 'blur' }],
          type:[{ required: true, message: '请选择类型', trigger: 'change' }],
          options:[{required:true,message:'请输入选项',trigger: 'blur'}],
          titleExam:[{ required: true, message: '请输入名称', trigger: 'blur' }],
          parse:[{ required: true, message: '请输入解析', trigger: 'blur' }],
          questionCode:[{ required: true, message: '请选择分类', trigger: 'blur' }],
          able:[{ required: true, message: '请输入标签', trigger: 'blur' }],
          stemLog:[{ required: true, message: '请选择题干', trigger: 'change'}],
          stemId:[{ required: true, message: '请选择题干', trigger: 'change'}],
        },
        titleList:[],
        editForm:{},
        typeInfoModel:[],
        modelPagination:{
          pageNo: 1,
          title:''
        },
        editShow:false,
        editType:false,
        addStem:false,
        stemValue:'',
        stemTable:[],
        stemPagination:{
          sizeOptions:['10','30', '50'],
          total:0,
          current: 1,
          pageSize:10,
        },
        stemColumns:[
          {
            title: '题干名称',
            scopedSlots: {customRender: 'name'},
          },
        ],
        selectedRowKeys: [],
      };
    },
    created() {},
    mounted() {
      this.getMenu();
      // this.load();
    },
    methods: {
      onSelectChange(selectedRowKeys) {
        this.selectedRowKeys = selectedRowKeys;
        if (this.addShow===true){
          this.addForm.stemId=Number(this.selectedRowKeys.toString())
          let stem = this.stemTable.find(v => {
            return v.id === this.addForm.stemId
          })
          this.addForm.stemLog=stem.name
        }
        if (this.editShow===true){
          this.editForm.stemId=Number(this.selectedRowKeys.toString())
          let stem = this.stemTable.find(v => {
            return v.id === this.editForm.stemId
          })
          this.editForm.stemLog=stem.name
        }

      },
      //题干addBtn
      stemAdd(){
        if (this.stemTable[this.stemTable.length - 1].name!==''){
          this.stemTable.push({
            id:this.stemTable.length+1,
            name:''
          })
        }else {
          this.$message.warning('请完善上一项并提交再新增')
        }
      },
      //新增题干
      async addStemOk(){
        if (this.stemValue!=='' && this.stemValue!==null){
          const data={
            name:this.stemValue
          }
          const add = await  postStem(data)
          if (add.code===0){
            this.getStem(this.stemPagination.current,this.stemPagination.pageSize)
            this.stemValue=''
            this.$message.success('新增成功')
          }
        }else {
          this.$message.error("输入有空~")
        }
      },
      //题干搜索
      stemSearch(value){
        this.stemName=value
        this.getStem(1,this.stemPagination.pageSize,value)
      },
      stemPage(current, pageSize){
        this.stemPagination.current = current
        this.stemPagination.pageSize = pageSize
        this.getStem(current, pageSize,this.stemName)
      },
      //滚动条监听
      popupScroll_questionCode(e){
        const {target} = e
        const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
        const clientHeight = target.clientHeight //滑动最低高度
        if(scrollHeight < clientHeight + 2){
          this.modelPagination.pageNo++
          this.getExamModel()
        }
      },
      select_questionCode(value) {
        // //先清空，防止鼠标滑动时添加的数据输入时添加的数据重复
        this.typeInfoModel = []
        this.modelPagination.title = value
        this.modelPagination.pageNo = 1
        this.getExamModel()
      },
      //获取题干
      async getStem(pageNum,pageSize,name,id){
        const info = await getTitleStem(pageNum,pageSize,name,id)
        if (info.code===0){
          this.stemTable=info.data.content
          this.stemPagination.total=info.data.count
        }
      },
      async editTitle(e) {
        if (e.target.value !== '' & e.target.value !== undefined && e.target.value !== null){
          const search =await getTitleList(e.target.value)
          this.titleList = search.data
          let arr = []
          arr.push({
            name: e.target.value
          })
          for(let i = 0; i < this.titleList.length; i++) {
            for(let j = 0; j < arr.length; j++) {
              if(arr[j].name === this.titleList[i].name){
                this.$message.error('题目名称重复，重新输入')
              }}}
        }else {
          this.titleList = []
        }
      },
      //修改选项添加
      editOptions(){
        let ifTrue = true
        this.editForm.options.forEach(item => {
          if (item.title === '') {
            this.$message.warning('请完善选项')
            ifTrue = false
          }})
        if (ifTrue) {
          this.editForm.options.push({
            answer:0,
            title:'',
          })}
      },
      delEditOptions(item,index){
        this.editForm.options.splice(index, 1)
      },
      //修改是否显示
      resultKeyEdit(row,index){
        let all = document.getElementById("check"+index);
        if (all.checked===true){
          row.answer = 1
        }else {
          row.answer = 0
        }
      },
      resultRadioEdit(row,index){
        row.answer = 1
        this.editForm.options.forEach((value,subIndex)=>{
          if (subIndex!==index){
            value.answer=0
          }
        })
      },
      openSample(code){
        // code加上时间防止重复
        this.propList = {
          visible:true,
          code:code+'202303221012',
        }
      },
      async editBtn(row){
        this.editId = row.questionId
        const edit =await getTitleId(row.questionId)
        if (edit.code === 0){
          this.editForm = edit.data
          if (this.editForm.hasOwnProperty('stemId')){
            this.editType=true
            await this.getStem(1, 10, '', this.editForm.stemId)
            this.editForm.stemLog=this.stemTable[0].name
          }

          this.editShow = true
          await this.getExamModel()
          //单选题判断正确答案赋值
          setTimeout(() => {
            if (this.editForm.type==='radio'){
              let arr = document.getElementsByName("radio")
              arr.forEach((row,index)=>{
                if (row.value==='1'){
                  row.checked=true
                }
              })

            }
          },100);

        }
      },


      async edit(){
        this.$refs.editItem.validate(async valid =>{
          if(valid){
            let arr =[]
            let edit
            const form=this.editForm
            if (this.editForm===false){
              for (let i=0;i<this.editForm.options.length;i++){
                arr.push({
                  title:this.editForm.options[i].title,
                  answer:this.editForm.options[i].answer,
                  id:i
                })
              }
              const data={
                name:form.name,
                options:arr,
                parse:form.parse,
                questionCode:form.questionCode,
                type:form.type,
                able:form.able,
              }
              edit =await putTitleExam(this.editId,data)
            }else {
              for (let i=0;i<this.editForm.options.length;i++){
                arr.push({
                  title:this.editForm.options[i].title,
                  answer:this.editForm.options[i].answer,
                  id:i,
                })
                if (this.editForm.options[i].answer===1){
                  arr[i].socore=this.editForm.options[i].socore
                }
              }
              const data={
                name:form.name,
                options:arr,
                parse:form.parse,
                questionCode:form.questionCode,
                type:form.type,
                able:form.able,
                stemId:form.stemId
              }
              edit =await putTitleStem(this.editId,data)
            }
            if (edit.code === 0){
              //刷新
              let data = {
                typeId:this.id,
                beginDate: this.StartTime,
                endDate: this.EndTime,
                replyStatus: this.replyStatus,
                page: this.pageNo,
                pageSum: this.pageSum,
              };
              const res = await getErrSelectAll(data);
              if (res.code === 200) {
                this.messageList = res.data;
                this.total = res.count;
              }
              this.$message.success('修改成功')
              this.$refs.editItem.resetFields();
              this.editForm = {}
              this.editShow = false
              this.typeInfoModel = []
              this.modelPagination.title = ''
              this.modelPagination.pageNo = 1
            }
          }else {
            this.$message.error("输入有空~")
            return false;
          }
        })
      },
      //修改
      editOk(){
        for(let i = 0; i < this.editForm.options.length; i++) {
          if (this.editForm.options[i].answer === 1 ){
            this.edit()
            return true;
          }
        }
        this.$message.error('未选择正确答案')
        return  false;
      },

      //分类用于对话框
      async getExamModel(){
        let data = {
          title:this.modelPagination.title,
          page_no:this.modelPagination.pageNo
        }
        const exam = await getExamLeft(data)
        if (exam.code === 0 && exam.data.length){
          let list = exam.data
          for (let i=0;i<list.length;i++){
            this.typeInfoModel.push({
              id: list[i].id,
              code: list[i].code,
              name: list[i].name,
            })
          }
        }
      },

      // 日期
      async onChange_range_picker(date, dateString){
        this.StartTime = dateString[0]
        this.EndTime = dateString[1]
        this.pageNo = 1
        this.pageSum = 10
        // this.id = this.menuList[0].id;
        let data = {
          typeId:this.id,
          beginDate: this.StartTime,
          endDate: this.EndTime,
          replyStatus: this.replyStatus,
          page: this.pageNo,
          pageSum: this.pageSum,
        };
        const res = await getErrSelectAll(data);
        if (res.code == 200) {
          this.messageList = res.data;
          this.total = res.count;
        //   console.log("数据啊",res.data)
        }
      },
      onCreated(editor) {
        this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
      },
      // 默认加载第一个模块内容
      async load() {
        console.log("第一个", this.menuList);
        this.id = this.menuList[0].id;
        this.typeName =  this.menuList[0].name;
        let data = {
          typeId:this.id,
          beginDate: this.StartTime,
          endDate: this.EndTime,
          replyStatus: this.replyStatus,
          page: this.pageNo,
          pageSum: this.pageSum,
        };
        const res = await getErrSelectAll(data);
        if (res.code == 200) {
          this.messageList = res.data;
          this.total = res.count;
        //   console.log("数据啊",res.data)
        }
      },
      // 切换左侧菜单获取内容
      async leftChange(item) {
        this.id = item.id;
        this.timeVal = []
        this.StartTime = ''
        this.EndTime = ''
        this.replyStatus = ''
        this.pageNo = 1
        this.pageSum = 10
        this.typeName = item.name
        let data = {
          typeId:this.id,
          beginDate: this.StartTime,
          endDate: this.EndTime,
          replyStatus: this.replyStatus,
          page: this.pageNo,
          pageSum: this.pageSum,
        };
        const res = await getErrSelectAll(data);
        if (res.code == 200) {
          this.messageList = res.data;
          this.total = res.count;
        //   console.log("数据",res.data);
        }
      },
      // 获取列表
      async getTable(id) {
        let data = {
          typeId:this.id,
          beginDate: this.StartTime,
          endDate: this.EndTime,
          replyStatus: this.replyStatus,
          page: this.pageNo,
          pageSum: this.pageSum,
        };
        const res = await getErrSelectAll(data);
        if (res.code == 200) {
          this.messageList = res.data;
          this.total = res.count;
          console.log("数据",res.data);
        }
      },
      // 分页查询
      pageChange(pageNo, pageSum) {
        this.pageNo = pageNo;
        this.pageSum = pageSum;
        this.getTable()
      },
      // 记录列表的分页   (按人)
      async recordPageChange(pageNo, pageSum) {
        console.log(pageNo, pageSum)
        this.recordPageNo = pageNo;
        this.recordPageSum = pageSum;
        // 获取列表并赋值 
        const res = await getselectAllByMemberId(this.createdId,this.recordPageNo,this.recordPageSum);
        if (res.code == 200) {
          console.log("数据",res)
          this.recordList = res.data;
          this.recordTotal = res.count
        }
      },
      // 记录列表的分页   (按题目)
      async recordTitlePageChange(pageNo, pageSum) {
        // console.log(pageNo, pageSum)
        this.recordTitlePageNo = pageNo;
        this.recordTitlePageSum = pageSum;
        // 获取列表并赋值
        const res = await getselectAllByQuestionId(this.recordQuestionId,this.recordTitlePageNo,this.recordTitlePageSum);
        if (res.code == 200) {
          this.recordByTitleList = res.data;
          this.recordTitleTotal = res.count
        }
      },
      //弹出日历和关闭日历的回调
      openChangeOne(status) {
        //status是打开或关闭的状态
        if (status) {
          this.yearShowOne = true;
        }
      },
      changRadio(e) {
        switch (e) {
          case "1":
            this.replyStatus = 1,
            this.pageNo = 1;
            this.pageSum = 10;
            this.search();
            break;
          case "0":
            this.replyStatus = 0;
            this.pageNo = 1;
            this.pageSum = 10;
            this.search();
            break;
        }
      },
      // 查询
      async search() {
        let data = {
          typeId:this.id,
          beginDate: this.StartTime,
          endDate: this.EndTime,
          replyStatus: this.replyStatus,
          page: this.pageNo,
          pageSum: this.pageSum,
        };
        const res = await getErrSelectAll(data);
        if (res.code == 200) {
          this.messageList = res.data;
          this.total = res.count;
        }
      },
      // 获取菜单
      async getMenu() {
        let data = {
          page: 1,
          pageSize: 999,
        };
        const res = await getMoKuaiData(data);
        if (res.code == 200) {
          this.menuList = res.data;
          this.load();
        }
      },
      // 1.根据姓名获取详情
      async getByTopic(record){
        this.addVisible = true
        this.tableTitle = "纠错记录" 
        this.userName = record.createdByName
        this.hospitalName = record.hospitalName
        this.hospitalOffice = record.hospitalOffice
        this.jobTitle = record.jobTitle
        this.createdId = record.createdBy //存一个id给分页用
        // 获取列表并赋值 
        const res = await getselectAllByMemberId(record.createdBy,this.recordPageNo,this.recordPageSum);
        if (res.code == 200) {
          console.log("数据",res)
          this.recordList = res.data;
          this.recordTotal = res.count
        }
      },
      // 2.根据题目名称获取详情
      async getByName(record){
        this.addVisible = true
        this.tableTitle = "题目记录" 
        this.questionName = record.questionName
        this.recordQuestionId = record.questionId
        // 获取列表并赋值
        const res = await getselectAllByQuestionId(this.recordQuestionId,this.recordTitlePageNo,this.recordTitlePageSum);
        if (res.code == 200) {
          console.log("数据",res)
          this.recordByTitleList = res.data;
          this.recordTitleTotal = res.count
        }
      },
      add() {
        this.addVisible = true;
      },
      addCancel() {
        this.addVisible = false;
        this.recordTitlePageNo = 1
        this.recordTitlePageSum = 10
        this.recordPageNo = 1
        this.recordPageSum = 10
      },
      // 打开详情
      getDeatil(record) {
        this.replyOrDeatil = "详情"
        this.editVisible = true;
        this.userName = record.createdByName
        this.hospitalName = record.hospitalName
        this.hospitalOffice = record.hospitalOffice
        this.jobTitle = record.jobTitle
        this.ERRDeatailsId = record.id
        this.getERRDeatails()
      },
      // 打开回复
      toReply(record){
        this.editVisible = true;
        this.replyOrDeatil = "回复"
        this.userName = record.createdByName
        this.hospitalName = record.hospitalName
        this.hospitalOffice = record.hospitalOffice
        this.jobTitle = record.jobTitle
        this.ERRDeatailsId = record.id
        this.getERRDeatails()
        this.formContent = record.replyContent
        // console.log("应该就是一样的啊吗，没事的",record)
      },
      // 纠错记录详情接口  
      async getERRDeatails(){
        // 获取列表并赋值
        const res = await getSelectById(this.ERRDeatailsId);
        if (res.code == 200) {
        //   console.log("详情数据",res)
          this.RelatedQuestions = res.data.questionName
          this.ErrorCorrectionType = res.data.errorType  //这个要处理
          this.NotesContent = res.data.errorContent
          this.CorrectionTime = res.data.createdTime
          this.replyContent = res.data.replyContent
          this.recoveryTime = res.data.replyTime
          this.detailReplyStatus = res.data.replyStatus
        //   console.log("数据类型啊",this.ErrorCorrectionType )
          var ceshishuju = ''
          for(var i = 0;i< this.ErrorCorrectionType.length;i++){
            ceshishuju = " "+this.ErrorCorrectionType[i] + ceshishuju 
          }
          console.log("查看处理",ceshishuju)
          this.Error2CorrectionType = ceshishuju
        }
      },
      editCancel() {
        this.editVisible = false;
        this.formContent = '' //关闭表单先清空
      },
      async tijiaoOK(){
        // 提交回复
        let data ={
          id:this.ERRDeatailsId,//纠错记录id
          replyContent:this.formContent
        }
        console.log("查看回复内容",data)
        const res = await putReply(data);
        if (res.code == 200) {
          this.$message.success("回复成功");
          this.editVisible = false;
          this.getTable()
        }
      },
      //  导出该模块 ExportThisModule
      async ExportThisModule(){
        console.log("当前模块的id",this.id,this.typeName)
        const data = {
          typeId:this.id,
          ExelName:this.typeName +"纠错记录",
          beginDate: this.StartTime,
          endDate: this.EndTime,
        }
        const response = await exportQuestionErrorCorrectionDataToExcel(data)
      },
      // 导出全部模块
      async ExportAllModule(){
        // console.log("当前模块的id",this.id,this.typeName)
        const data = {
          typeId:'',
          ExelName:"所有模块的纠错记录",
          beginDate: this.StartTime,
          endDate: this.EndTime,
        }
        const response = await exportQuestionErrorCorrectionDataToExcel(data)
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .exercisesType {
    margin: -15px -15px;
    background-color: rgba(240, 242, 245, 1);
  
    .menu_select {
      background-color: white;
      width: 18%;
      min-height: 750px;
    }
    .InfoTable {
      background-color: white;
      width: 81%;
      min-height: 750px;
      .navTop {
        width: 100%;
        height: 60px;
        display: flex;
        padding:0 15px;
        align-items: center;
        justify-content: space-between;
      }
      .nav {
        width: 100%;
      }
    }
  }
  ::v-deep .ant-descriptions-view {
    width: 100%;
    overflow: hidden;
    border-radius: 4px;
}
::v-deep .ant-table-thead > tr > th {
  font-weight: bold;
}

::v-deep .ant-drawer-title {
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
}

// ::v-deep  .ant-descriptions-item-content {
//     display: table-cell;
//     color: rgba(0, 0, 0, 0.65);
//     font-size: 14px;
//     line-height: 1.5;
// }

::v-deep .ant-descriptions-item-label {
    color: rgba(0, 0, 0, 0.65);
    font-weight: normal;
    font-size: 14px;
    line-height: 1.5;
}
  </style>
  